export const useSideNavStore = defineStore('sideNavStore', () => {
  const isSideMenuOpened = ref<boolean>(false);
  const isSideMenuOpenedType = ref<string | null>();
  const isSideMenuCatalogOpened = computed(() => isSideMenuOpenedType.value === 'catalog');

  return {
    isSideMenuOpened,
    isSideMenuOpenedType,
    isSideMenuCatalogOpened,
  };
});
