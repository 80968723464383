type TSideMenu = 'mobile' | 'catalog';

export const useSideNav = () => {
  const { open, close } = useDrawers();

  const { isSideMenuOpened, isSideMenuOpenedType } = storeToRefs(useSideNavStore());

  const openSideMenu = (type: TSideMenu) => {
    open('sideNav');
    isSideMenuOpened.value = true;
    isSideMenuOpenedType.value = type;
  };

  const closeSideMenu = () => {
    close('sideNav');
    isSideMenuOpened.value = false;
  };

  const openSideMenuMobile = () => {
    openSideMenu('mobile');
  };

  const openSideMenuCatalog = () => {
    openSideMenu('catalog');
  };

  return {
    openSideMenu,
    closeSideMenu,
    openSideMenuMobile,
    openSideMenuCatalog,
  };
};
